export const states = {
    application: {
        ready: "ready",
    },
    user: {
        current: "current",
    },
};

function ApplicationState() {
    this._state = {
        _stateMap: {},
        get: function (key) {
            return this._stateMap[key];
        },
        set: function (key, data) {
            this._stateMap[key] = data;
        },
        has: function (key) {
            return !!this._stateMap[key];
        },
    };

    this._subscribers = {
        _subscribersMap: {},
        get: function (key) {
            return this._subscribersMap[key];
        },
        set: function (key, data) {
            this._subscribersMap[key] = data;
        },
        has: function (key) {
            return !!this._subscribersMap[key];
        },
    };

    this.update = function (key, data) {
        this._state.set(key, data);
        this._notify(key, data);
    };

    this.on = function (key, callback) {
        var unsubscribe = function () {
            this._unsubscribe(key, callback);
        }.bind(this);

        var handler = { callback: callback, unsubscribe: unsubscribe };
        if (this._subscribers.has(key)) {
            var listeners = this._subscribers.get(key);
            listeners.push(handler);
        } else {
            this._subscribers.set(key, [handler]);
        }

        var initialState = this._state.get(key);

        if (callback && initialState) callback(initialState, unsubscribe);

        return unsubscribe;
    };

    this._notify = function (key, data) {
        var listeners = this._subscribers.get(key);

        if (!listeners) return;
        listeners.slice().forEach(function (listener) {
            listener.callback && listener.callback(data, listener.unsubscribe);
        });
    };

    this._unsubscribe = function (key, callback) {
        var arrayWithListeners = this._subscribers.get(key);
        var index = -1;
        var foundMatch = arrayWithListeners.some(function (listener, listenerIndex) {
            if (listener.callback === callback) {
                index = listenerIndex;
                return true;
            }
        });

        return foundMatch ? arrayWithListeners.splice(index, 1) : arrayWithListeners;
    };
}

export default new ApplicationState();
