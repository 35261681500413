import * as Sentry from "@sentry/browser";
import "../less/build.less";
import applicationState, { states } from "./system/application-state";
import { Sentry as SentryConfig } from "./system/config";

if (SentryConfig.isConfigured) {
    Sentry.init({
        dsn: SentryConfig.dsn,
    });
    Sentry.setTag("source", "Consumer Science Platform");

    applicationState.on(states.user.current, ({ isAuthenticated }) => {
        if (!isAuthenticated) {
            Sentry.setUser();
        }
    });
}

import(/* webpackChunkName: "csp" */ "./main")
    .then((module) => {})
    .catch((error) => console.log("An error occurred while loading the component", error));
