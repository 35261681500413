const configElementId = "csp-configuration";
const configElement = document.getElementById(configElementId);
if (!configElement) throw new Error(`Could not find configuration element '${configElementId}'`);

function endsWith(text, suffix) {
    return text.indexOf(suffix, text.length - suffix.length) !== -1;
}

const {
    apiUrl,
    sentryDSN,
    nAuthUri,
    nAuthRedirectUri,
    nAuthSilentRedirectUri,
    nAuthPostLogoutRedirectUri,
    nAuthTokenCookieDomain,
    nAuthClient,
    nAuthScopes,
    includeCookieDomain,
    adminUrl,
} = JSON.parse(configElement.textContent);

export const apiBaseUrl = endsWith(apiUrl, "/") ? apiUrl : `${apiUrl}/`;

export const adminBaseUrl = endsWith(adminUrl, "/") ? adminUrl : `${adminUrl}/`;

export const Sentry = {
    isConfigured: !!sentryDSN,
    dsn: sentryDSN,
};

export const NAuth = {
    nAuthUri,
    nAuthRedirectUri,
    nAuthSilentRedirectUri,
    nAuthPostLogoutRedirectUri,
    nAuthTokenCookieDomain,
    nAuthClient,
    nAuthScopes,
    includeCookieDomain,
};

export const EXPORTS_FOLDER_NAME = "Exports";

export const MMM_DATA_UPLOAD_ROLE = "csp.client-uploads.mmm-data";
export const BRANDTRACKER_MEDIA_INVESTMENT_UPLOAD_ROLE = "csp.client-uploads.media-investment";
export const SHARE_FILES_WITH_NEPA_ROLE = "csp.client-uploads.share-files";
export const CSP_ADMIN_ROLE = "csp.admin";

export const BRANDTRACKER_MEDIA_INVESTMENT_UPLOAD_FEATURE_ID = 2;
export const MMM_DATA_UPLOAD_FEATURE_ID = 4;
export const SHARE_FILES_WITH_NEPA_FEATURE_ID = 1;